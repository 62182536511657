<template>
  <div id='main-panel'>
    <div class='d-flex'>
      <div class='flex-grow-1'>
        <h1 class='black--text'>Rater Templates</h1>
      </div>
      <div class='flex-grow-1 text-right'>
        <v-menu offset-y>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              color='primary'
              v-bind='attrs'
              v-on='on'
              :loading='loading.availableTypes'
            >
              + NEW TEMPLATE
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for='templateType in availableTypes' :key='templateType.id'
                         :to="{name: 'rater-templates-create',query:{lob:templateType.attributes.lob_code}}">
              <v-list-item-title>
                {{ templateType.attributes.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>


    <div id='template-list' style='margin-bottom:300px;'>
      <v-skeleton-loader :loading='loading.templates' type='list-item-two-line'
                         v-if='loading.templates'
                         height='60px'
                         :elevation='5'
                         class='mb-2'
                         v-for='n in 5'
                         style='opacity: 0.5'
      ></v-skeleton-loader>
      <template-list-row :template='template' v-for='template in templates' :key='template.id'
                         @templateDeleted='loadTemplates' v-if='loading.templates === false' />

      <div v-if='loading.templates === false && templates.length === 0'>
        <div id='no-templates' class='text-center'>
          There are no existing templates for your agency.
        </div>
        <div class='text-center mt-5'>
          <v-menu offset-y>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                v-bind='attrs'
                v-on='on'
                :loading='loading.availableTypes'
              >
                Create First Template
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for='templateType in availableTypes' :key='templateType.id'
                           :to="{name: 'rater-templates-create',query:{lob:templateType.attributes.lob_code}}">
                <v-list-item-title>
                  {{ templateType.attributes.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import TemplateListRow from '@/pages/rater/templates/components/TemplateListRow.vue'

export default {
  components: { TemplateListRow },
  data() {
    return {
      availableTypes: [],
      templates: [],

      loading: {
        templates: true,
        availableTypes: true,
        cloning: false,
      },
    }
  },
  methods: {
    loadAvailableTypes() {
      this.$api.raterTemplates.getTypesAvailable().then((response) => {
        this.availableTypes = response.data
        this.loading.availableTypes = false
      }).catch((error) => {
        console.log(error)
      })
    },
    loadTemplates() {
      this.loading.templates = true

      this.$api.raterTemplates.index().then((response) => {
        this.templates = response.data
        this.loading.templates = false
      }).catch((error) => {
        console.log(error)
      })
    },
    cloneTemplate(templateId) {
      if (this.loading.cloning) {
        return
      }

      this.loading.cloning = true

      this.$api.raterTemplates.clone({
        templateId,
      }).then((response) => {
        this.$router.push({
          name: 'rater-templates-edit',
          params: {
            templateId: response.data.id,
          },
        })
      }).catch((error) => {
        this.loading.cloning = false
        console.log(error)
      })
    },
  },
  mounted() {
    this.loadAvailableTypes()
    this.loadTemplates()
  },
}
</script>
<style lang='scss' scoped>
#main-panel {
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
  background: #fff;
}

#template-list {
  margin-top: 60px;
}

#no-templates{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #3A3541;
}
</style>

<template>
  <div class='d-flex template-list-item'>
    <div class='flex-grow-1'>
      <span class='template-list-item-title'>{{ template.attributes.title }}</span>
      <span
        class='template-list-item-type'>{{ template.related.policy_type.data.attributes.name }}</span>
      <span
        class='template-list-item-meta'>
      <span
        v-if='template.related.user.data'>Created by {{ template.related.user.data.attributes.name
        }} on</span> {{ template.attributes.pretty_date }}
    </span>
    </div>
    <div class='flex-shrink-1 mt-3'>
      <v-tooltip bottom>
        <template v-slot:activator='{ on, attrs }'>
          <v-btn v-bind='attrs' v-on='on' fab plain x-small
                 :to="{name: 'rater-templates-edit',params:{template:template.id}}">
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator='{ on, attrs }'>
          <v-btn v-bind='attrs' v-on='on' fab plain x-small
                 @click.prevent='cloneTemplate()'>
            <v-icon>{{ icons.mdiContentCopy }}
            </v-icon>
          </v-btn>
        </template>
        <span>Clone</span>
      </v-tooltip>

      <v-dialog v-model='showConfirmDelete' width='500'>
        <template v-slot:activator='modalActivator'>
          <v-tooltip bottom>
            <template v-slot:activator='tooltipActivator'>
              <v-btn v-bind='tooltipActivator.attrs' v-on='tooltipActivator.on' fab plain x-small
                     @click='deleteTemplate()'>
                <v-icon>{{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-card-title class='text-h5 danger white--text'>
            <span class='white--text'>Warning!</span>
          </v-card-title>

          <v-card-text class='pa-3'>
            Are you sure you want to delete this template? This action cannot be undone and all data will be removed.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color='danger'
              class='white--text'
              @click='confirmDeleteTemplate()'
            >
              Yes! Delete template
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              color='secondary'
              text
              @click='showConfirmDelete = false'>
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mdiContentCopy, mdiDelete, mdiPencil } from '@mdi/js'

export default {
  props: [
    'template',
  ],
  data() {
    return {
      showConfirmDelete: false,
      icons: {
        mdiPencil, mdiDelete, mdiContentCopy,
      },
    }
  },
  methods: {
    deleteTemplate() {
      this.showConfirmDelete = true
    },
    confirmDeleteTemplate() {
      this.$api.raterTemplates.delete({
        templateId: this.template.id,
      }).then((response) => {
        this.$emit('templateDeleted')
        this.showConfirmDelete = false
      }).catch((error) => {

      })
    },
    cloneTemplate() {
      this.$api.raterTemplates.clone({
        templateId: this.template.id,
      }).then((response) => {
        this.$router.push({ name: 'rater-templates-edit', params: { template: response.data.id } })
      }).catch((error) => {

      })
    },
  },
}
</script>

<style lang='scss' scoped>
.template-list-item {
  border: 1px solid rgba(58, 53, 65, 0.08);
  box-shadow: 0px 2px 8px 0px rgba(58, 53, 65, 0.1);
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;

  .template-list-item-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    display: block;
    color: rgba(58, 53, 65, 1);
  }

  .template-list-item-type {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 53, 65, 0.68);
    display: inline-block;
    margin-right: 8px;
  }

  .template-list-item-meta {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 53, 65, 0.68);
  }
}
</style>
